import { LoadingButton } from "@mui/lab";
import {
  
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";


const HelperDialog = ({ helperVisible, info }) => {
  const GetTitle = () => {
    if (helperVisible === "chrome") {
      return "Redirecting to Chrome Store...";
    } else if (helperVisible === "edge") {
      return "Redirecting to Edge Store...";
    } else {
      return info.title;
    }
  };

  const GetHelperLoading = () => {
    if (helperVisible === "chrome") {
      setTimeout(() => {
        window.location.href = info.url;
      }, 3000);
      return (
        <>
          <Box
            sx={{
              backgroundImage: `url(/img/helper_${helperVisible}.png)`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              height: 320,
              width: "100%",
            }}
          ></Box>
          <Box mt={1}>
            <Typography variant="body2" align="center">
              Click on "Add to Chrome" on the next page to install...
            </Typography>
          </Box>
        </>
      );
    } else if (helperVisible === "edge") {
      setTimeout(() => {
           window.location.href = info.url;
      }, 3000);
      
      return (
        <>
          <Box
            sx={{
              backgroundImage: `url(/img/helper_${helperVisible}.png)`,
              backgroundRepeat: "no-repeat",              
              height: 320,
              width: "100%",
            }}
          ></Box>
          <Box mt={1}>
            <Typography variant="body2" align="center">
              Click on "Get" on the next page to install...
            </Typography>
          </Box>
        </>
      );
    } else {
      return info.text;
    }
  };

  return (
    <Dialog
      fullWidth
      open={true}
      disableEscapeKeyDown={true}
      sx={{
        backgroundColor: "rgba(0,0,30,0.4)",
      }}
    >
      <DialogTitle>
        <GetTitle />
      </DialogTitle>
      <DialogContent dividers>
        <GetHelperLoading />
      </DialogContent>
      <DialogActions>
        <LoadingButton loading variant="outlined">
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default HelperDialog;
