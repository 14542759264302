import { Container, Link, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import React from 'react'

const Footer = ({domain}) => {
    return (        
        <footer>
            <Container>
                <Typography variant="body2" align="center" mt={3}>
                Copyright &copy; { new Date().getFullYear() } {domain}. All rights reserved.
                </Typography>
                <Typography variant="body2" align="center">
                    <Link component={RouterLink} to="/terms">Terms of Us</Link> | <Link component={RouterLink} to="/privacy">Privacy Policy</Link> | <Link component={RouterLink} to="/uninstall">Uninstall</Link>
                </Typography>
            </Container>
        </footer>
    )
}

export default Footer
