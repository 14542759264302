import React, { useContext } from "react";
import LayoutFront from "../components/LayoutFront";
import BgImg from "../assets/bg.png";
import { Box } from "@mui/system";
import { Container, Typography } from "@mui/material";
import { AppContext } from "../AppContext";
import { Helmet } from "react-helmet-async";

const Home = () => {
  var context = useContext(AppContext);

  return (
    <LayoutFront>
      <Box
        alignItems="center"
        sx={{
          backgroundImage: `url(${BgImg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          minHeight: 700,
        }}
        display="flex"
      >
        <Container>
          <Box width={500} alignSelf="flex-start">
            <Helmet>
              <title>Welcome - {context.domainTitle}</title>
            </Helmet>
            <Typography>
              At {context.domainTitle}, we believe online privacy should be
              simple. That's why our extension has one single purpose —
              protecting your privacy in {context.browser}. Together, these
              privacy essentials seamlessly help prevent your personal
              information from being exposed during everyday online activity.
            </Typography>
          </Box>
        </Container>
      </Box>
    </LayoutFront>
  );
};

export default Home;
