import { Container } from "@mui/material";
import React, { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { AppContext } from "../AppContext";
import LayoutFront from "../components/LayoutFront";

const Privacy = () => {
  var context = useContext(AppContext);

  return (
    <LayoutFront>
          <Container>
            <Helmet>
              <title>Terms of Use - {context.domainTitle}</title>
            </Helmet>
            <p> {context.domainTitle} PRIVACY POLICY </p>
            <p>MAY CHANGE FROM TIME TO TIME</p>
            <p>PLEASE CHECK BACK FREQUENTLY FOR UPDATES</p>
            <p>Effective as of October 27, 2016</p>
            <p>
              {context.domainTitle} has established this Privacy Policy to disclose our
              information gathering and dissemination practices for the
              {context.domainTitle} Application and our Services, and to allow you to make
              an informed decision about how you will use our Services. Our
              "Services" include the {context.domainTitle} Application, and all related
              services, applications, components, programs, software, listings,
              directories, tools, updates, databases and information made
              available and utilized. References to "you" in this Agreement
              means you, your duly authorized representatives and any entity you
              may represent in connection with your use of the Services.
            </p>
            <p>
              By using our Services, you are consenting to the terms of this
              Privacy Policy and all of the activities described herein. If you
              do not agree to be bound by this Privacy Policy, you should
              uninstall {context.domainTitle} and not use our Services. We reserve the
              right to enter into new or different lines of business with
              consumers and if and to the extent we do, we may display a
              different privacy policy to the consumer for such lines of
              business.
            </p>
            <p>
              This Privacy Policy addresses only our practices with respect to
              information we collect in the course of using our Services and
              does not apply to information about you that might be collected by
              a third party website or an offering linked to or otherwise
              accessible from our Services. Any information collected, obtained
              and/or used by any such third parties is subject to the privacy
              policies of those third parties. We encourage you to review the
              policies of such third parties. Finally, to the extent any of our
              Services are undertaken in conjunction with or as a co-branded
              service with a third party, then both our Privacy Policy and such
              third party's privacy policy may apply.
            </p>
            <h3>Collection of Information and Your Privacy Rights</h3>
            <h3>What information we collect and how we collect it.</h3>
            <p>1. Collection of Information</p>
            <p>
              (a) {context.domainTitle} does not, generally, collect, track or maintain
              personally identifiable information about consumers that use our
              Services. However, we may collect personally-identifiable
              information through the use of our Services upon your contacting
              us so that we may respond to your questions or comments, such as
              your name, email or mailing address, or other information you
              include when contacting us.
            </p>
            <p>(b) We also may collect and store URLS that you visit.</p>
            (c) When you use or interact with our Services, we may also collect
            certain anonymous information that is not sufficient to permit
            someone to identify you by name, mailing address, telephone number,
            social security number, driver's license or other government-issued
            identification. The anonymous information we may have access to,
            obtain, retain and use may include:
            <ul>
              <li>
                Information about your use of our Services, such as the
                frequency with which you use them, how you use and interact with
                them, when you visit them, and your responses to the offerings
                and advertisements made available to you through them, among
                other things; and
              </li>
              <li>
                Information that may be gathered or received when you use the
                Services or visit other websites, including, without limitation,
                the following:
                <ul>
                  <li>
                    The type of browser you are using when using our Services,
                    including your browser's "user agent" (e.g., Internet
                    Explorer, Safari, and other browsers);
                  </li>
                  <li>
                    Your operating system type, whether Mac OS, or Windows, and
                    which version of Windows;
                  </li>
                  <li>
                    Your Internet protocol (IP) address and information about
                    the version of any software comprising a portion of the
                    Services that you use;
                  </li>
                  <li>
                    Your geographic location and the domain name of your
                    Internet service provider (ISP); the URLs and websites that
                    you visit and have visited; and/or Diagnostic information
                    which describes how our Services are operating on your
                    system.
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              (d) All of the foregoing information is collectively referred to
              hereinafter as "Anonymous Information." We may also supplement
              Anonymous Information with additional information from other third
              parties in some cases. Additionally, those portions of the
              Services comprised of software have been configured to communicate
              to us any information relating to computer errors arising from use
              of such software, without providing any notice to you of the
              delivery of such communications. Such communications may include
              the configuration settings applicable at the time of the error. We
              use this data to help us clarify and fix performance issues with
              and to improve the software and the Services.
            </p>
            <p>
              (e) In addition, as part of the functionality provided by our
              Service, {context.domainTitle} assigns a unique user ID, which is stored in
              the computer registry. This unique user ID is combined with the
              information collected via the Services in order to provide you a
              more customized search experience, and to clarify, fix and improve
              the software and the Services.
            </p>
            <p>2. Use of Information</p>
            <p>
              (a) Anonymous Information is used to (i) operate and improve upon
              the Services, including all constituent parts of the Services such
              as the software, the websites and the various offerings, among
              other things, (ii) research and analyze your use of the Services,
              and (iii) present you with offers for other products, offerings,
              programs, features and/or services that may be of interest to you.
            </p>
            We will not share your Anonymous Information with third parties
            unless:
            <ul>
              <li>
                You have given us your prior consent to disclose such
                information;
              </li>
              <li>
                The third party is our parent, subsidiary, or an affiliated
                company owned by our parent company;
              </li>
              <li>
                We must disclose some or all of your Anonymous Information to
                authorized officers of the court in order to comply with valid
                legal process or to act in other urgent situations (as further
                discussed below); or
              </li>
              <li>
                Such information is needed by third-party advertising networks
                and advertisers who help deliver relevant ads when you are on a
                webpage. We only share anonymized or aggregated information to
                improve the relevancy of the ads you see. As mentioned below in
                section 3, these third-party advertising networks and
                advertisers may also use cookies and similar technologies to
                collect and track non-personally identifiable information such
                as demographic information, aggregated information, and Internet
                activity to assist them in delivering advertising that is more
                relevant to your interests.
              </li>
            </ul>
            <p>
              (b) We note above, but it is worth reiterating here, that your
              Anonymous Information and other information about you as a user of
              our Services, may be disclosed by us to those authorized officers
              of the court in response to valid legal process, including without
              limitation, a valid court order, search warrant or subpoena), as
              well as in other circumstances if we believe in good faith that
              there is an emergency that poses a threat to the safety of you or
              another person or that the Services are being used in the
              commission of a crime by your or another third party. Furthermore,
              we may disclose your Anonymous Information to protect our own
              rights, Services and property or those of our affiliated
              providers, in addition to providing you with the Services.
            </p>
            <p>
              (c) In the event that ownership of all or a portion of the
              Services currently provided by {context.domainTitle} were to change as a
              result of a merger, acquisition or transfer to another company,
              your Anonymous Information may be transferred as well.
            </p>
            <p>3. Web Beacons and Cookies</p>
            <p>
              (a) In providing the Services to you, we may use web beacons and
              cookies or similar technologies in conjunction with your Anonymous
              Information to personalize and improve your use of and experience
              with the Services, including, without limitation, to operate and
              improve the Service's offerings; to present, measure and research
              the effectiveness of our Service's offerings, advertisements, and
              communications to you, such as by determining which pages you
              visit, which ads you click on, communications you respond to, and
              functionality you use; to store and allow retrieval of information
              that you have provided to third party services that are integrated
              with the Services, and configuration preferences; and to customize
              the content and advertisements provided to you through the
              Services. In addition, third party advertisers might also use
              cookies or web beacons downloaded through our Services to
              accomplish many similar goals in connection with the effectiveness
              of their advertising through our Services and in general. We do
              not have access to the cookies or web beacons employed by
              advertisers through our Services and such cookies or web beacons
              are not governed by this Privacy Policy, but rather by the privacy
              policy of such advertiser. The cookies may reflect de-identified
              demographic or other data linked to data you voluntarily have
              submitted. You can learn more about cookies and opting-out of the
              receipt of cookies at http://www.aboutads.info/choices; you can
              learn more about cookies in general from
              http://www.cookiecentral.com.
            </p>
            <p>
              (b) Web beacons or clear GIFs are relatively small pieces of code
              placed on webpages that can be used for a number of purposes,
              including counting the users who visit that webpage, or to deliver
              a cookie to the browser of a user viewing that page. A cookie is a
              small text file transferred from {context.domainTitle} to your computer that
              records information about your preferences and use of the
              Services. We may also use such cookies to help us identify your
              location by determining your IP address, or to allow us to recall
              certain Service settings that you have chosen previously. You can
              control cookies through your browser's settings. However, if you
              reject all cookies, you may not be able to take advantage of most
              our Service offerings.
            </p>
            <p>4. We Are Committed to Security</p>
            <p>
              {context.domainTitle} stores Anonymous Information in a database on
              {context.domainTitle} computers. These computers have security measures, such
              as a firewall, to protect against the loss, misuse, and alteration
              of the information under {context.domainTitle} control. Notwithstanding such
              measures, {context.domainTitle} cannot guarantee that its security or privacy
              protection measures will prevent its computers from being
              illegally accessed and the data on them stolen or altered.
            </p>
            <p>
              Any personally-identifiable information which you may send us when
              contacting us is stored on our secure email exchange servers and
              deleted according to our company data retention policy. None of
              the personally-identifiable information you send us is used for
              any other purpose other than the matter for which you contacted
              us. We do not use nor combine this personally-identifiable
              information for any marketing purposes nor is it supplemented with
              any information from any other sources. We do not share the
              personal information you send us via the Contact Us feature among
              our other business divisions unless it is specifically related to
              the issue you have contacted us about, nor do we share it with any
              third parties unless we are to responding to subpoenas, court
              orders and other legal process as required by law.
            </p>
            <p>5. How You May Exercise Your Rights </p>
            <p>
              To prevent the display of {context.domainTitle} advertising on your computer,
              uninstall the {context.domainTitle} software program by following the
              instructions located at{" "}
              <a href="/uninstall" target="_blank">
                Uninstall
              </a>
              .
            </p>
            <p>
              In order to remove any cookies, check the instructions about your
              web browser to learn how to prevent placement of, or how to
              remove, cookies placed on your computer by {context.domainTitle}.
            </p>
            <p>6. General Internet Hazards</p>
            <p>
              Any time you receive files over the Internet from third parties,
              regardless of the application used, whether {context.domainTitle}, email or
              other application, you may be subjecting yourself to receiving
              harmful files or files that are not compatible with your operating
              system. Prior to accepting any files from any Internet user,
              including those from an account that appears familiar to you, we
              highly recommend verifying that the person sending you such files
              is the person you believe he or she is. You should be extremely
              careful about accepting files from users you do not trust, from
              users you do not know, or from users whose identity you have not
              been able to confirm. You should also be aware that communications
              sent over the Internet are not secure unless they are encrypted.
              {context.domainTitle} does not encrypt your communications through the
              Services. Further, your communications made through {context.domainTitle} may
              be routed through different countries which may have different
              laws, rules, regulations and standards regarding activities on the
              Internet. As such, {context.domainTitle} cannot accept any responsibility for
              any unauthorized access to your communications made through the
              Services or any loss of data.
            </p>
            <p>7. European Union Members - International Transfer of Data</p>
            <p>
              Our Services are international in scope. By using the Services you
              consent to your Anonymous Information and any personal information
              that you provide to us being sent and processed in other
              jurisdictions, including the United States and other jurisdictions
              outside the European Union and European Economic Area where there
              may be less stringent data protection laws.
            </p>
            <p>8. Minors Under Age 18</p>
            <p>
              The Services are intended for a general audience, and children and
              minors under the age of eighteen (18) are not permitted to access
              or use the Services, absent express consent from their parents or
              guardians. We do not intentionally solicit or collect personal
              information from anyone under the age of 18.
            </p>
            <p>9. Changes to this Privacy Policy</p>
            <p>
              {context.domainTitle} may update this Privacy Policy from time to time in its
              sole discretion, as reflected in the "Effective as of" date above.
              As such, you should review this Privacy Policy frequently by
              clicking on the "Privacy Policy" link at the bottom of
              {context.domain}, or visiting this link which you can
              bookmark:{" "}
              <a href="/privacy" target="_blank">
                Privacy
              </a>
              . If we make any substantial changes to this Privacy Policy, we
              will present you a notice via the {context.domainTitle} Movies Application
              and post a prominent notice on our home page with a copy of the
              previous changes along with any substantial changes we made.
            </p>
            <p>10. Who We Are</p>
            <p>
              Any references made in this Privacy Policy to "us", "we", "our"
              shall be deemed to have been made to {context.domainTitle}, as well as any
              company that controls {context.domainTitle}, directly or indirectly, and any
              other subsidiary of that controlling company.
            </p>
            <p>11. More Information</p>
            <p>
              For more information about protecting your privacy, you may wish
              to visit http://www.netcoalition.com and
              http://www.privacyalliance.org. In addition, the Federal Trade
              Commission Consumer Response Center offers a helpline at
              1-877-FTC-HELP (1-877-382-4357) to allow consumers to make
              comments or inquiries regarding companies and business practices.
            </p>
          </Container>

    </LayoutFront>
  );
};

export default Privacy;
