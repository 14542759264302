import React from "react";

export const appContent = {
    domain: "",
    domainTitle: "",
    browser: "",
    name: "",
    subdomain: "",
    update: () => {},        
  };
  
  export const AppContext = React.createContext(
    appContent // default value
  );