import { Container } from "@mui/material";
import React, { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { AppContext } from "../AppContext";
import LayoutFront from "../components/LayoutFront";

const Uninstall = () => {
  var context = useContext(AppContext);
  return (
    <LayoutFront>

          <Container>
            <Helmet>
              <title>Uninstall - {context.domainTitle}</title>
            </Helmet>
            <h2>How to uninstall {context.domainTitle}?</h2>
            <p>1. Click on Start - Control Panel</p>
            <p>
              2. Double click on "Programs and Features" or "Add/Remove
              Programs" - depending on your operating system
            </p>
            <p>
              3. Find "{context.domainTitle}" and click on uninstall:
              <br />
              <br />
            </p>

            <h2>How to remove {context.domainTitle} extension?</h2>
            <p>
              Open your browser Add-on settings and remove {context.domainTitle}
            </p>
          </Container>
    </LayoutFront>
  );
};

export default Uninstall;
