import { Container } from "@mui/material";
import React, { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { AppContext } from "../AppContext";
import LayoutFront from "../components/LayoutFront";

const Terms = () => {
  var context = useContext(AppContext);

  return (
    <LayoutFront>
      <Container>
        <Helmet>
          <title>Terms of Use - {context.domainTitle}</title>
        </Helmet>
        <h2>{context.domainTitle} Extension Terms of Service</h2>
        <p> Updated October 27, 2016</p>

        <p>
          Welcome to {context.domainTitle}. We hope that you will enjoy the
          great features of our unique website and proprietary{" "}
          {context.domainTitle} Application (the "
          <strong>{context.domainTitle} Application,</strong>" "
          <strong>{context.domainTitle}</strong>") and the services we provide
          (the "<strong>Services</strong>") as detailed below.{" "}
          {context.domainTitle} is service of {context.domainTitle} ("we," "us,"
          or "our") and these terms of use (the "
          <strong>Terms of Service</strong>") are a legal agreement between you
          and us setting forth, among other things, the terms and conditions for
          (i) your access and use of the {context.domainTitle} Application, the
          website where you downloaded the extension and any related
          sub-domains, which are operated by {context.domainTitle} (the "
          <strong>Website</strong>"), (ii) the download and use of the{" "}
          {context.domainTitle} Application and (iii) the provision of the
          Services.
        </p>

        <p>
          We reserve the right to change these Terms of Service from time to
          time without notice. You acknowledge and agree that it is your
          responsibility to review this Terms of Service periodically to
          familiarize yourself with any modifications. Your continued use of our
          Services or this site after such modifications will constitute
          acknowledgment and agreement of the modified terms and conditions. If
          we make any substantial changes to the Terms of Service, we will
          present you a notice via the {context.domainTitle} Application and
          post a prominent notice on our home page with a copy of the previous
          changes along with any substantial changes we made.
        </p>

        <h3>
          Services Accessible through the {context.domainTitle} Application.
        </h3>

        <p>
          If you decide to use the Services through the {context.domainTitle}{" "}
          Application, you will have access to the same features as the
          underlying Website use PLUS integrated search results and/or ads
          directly within the results pages of your favorite websites (Google,
          Amazon, TripAdvisor etc.). The ads will be displayed only when the{" "}
          {context.domainTitle} application is running and they will be marked
          as ads that are by {context.domainTitle} (rather than the underlying
          website).
        </p>

        <p>
          By downloading the {context.domainTitle} Application, you understand
          and expressly agree that: a {context.domainTitle} logo will be affixed
          on your search engine webpage (Google, etc.), usually below the search
          text box; and the results of your searches provided by{" "}
          {context.domainTitle} will be included at the top of the results pages
          of your search engine.
        </p>

        <p>
          The {context.domainTitle} Application is only compatible with Edge,
          Firefox and Chrome.
        </p>

        <p>
          The Resources are intended for a general audience, and children and
          minors under the age of eighteen (18) are not permitted to access or
          use the Resources, absent express consent from their parents or
          guardians. We do not intentionally solicit or collect personal
          information from anyone under the age of 18.
        </p>

        <p>
          While we take every precaution to ensure that the{" "}
          {context.domainTitle} Application is compatible with other
          applications installed on your device, it is still possible that it
          may interfere with the functionality of other applications. If you
          notice any problems with the {context.domainTitle} Application
          interfering with other applications on your device, please contact us
          at{" "}
          <a href="mailto:support@{context.domainTitle}">
            support@{context.domainTitle}
          </a>
          .
        </p>

        <br />

        <p>
          <strong>Third Party Sites. </strong>The Website, the{" "}
          {context.domainTitle} Application and the Services may contain links
          to third party sites or resources that are not owned or controlled by{" "}
          {context.domainTitle}. {context.domainTitle} has no control over, and
          assumes no responsibility for the content, privacy policies, practices
          or availability of any third party sites or resources or the products,
          services or other materials on or available from such sites or
          resources. You are and remain subject to the policies of those third
          parties where applicable. By using the Website, the{" "}
          {context.domainTitle} Application and the Services, you expressly
          relieve {context.domainTitle} from any and all liability (direct or
          indirect) arising from your use of any share functionality or
          third-party site or resource accessed from the Website, the{" "}
          {context.domainTitle} Application or the Services. We encourage you to
          ask questions before you disclose your personal information to anyone.
          We reserve the right to change, modify or delete links to third party
          websites without notice.
        </p>

        <p>
          <strong>Partners. </strong>
          {context.domainTitle} and/or its affiliated
          partners/associations/organizations which participate in the growth of
          the Services may present advertisements or promotional materials on or
          through the Website and/or the {context.domainTitle} Application
          (including, without limitation within/around the search results
          provided by {context.domainTitle}). Your dealings with, or
          participation in promotions of, any third-party advertisers on or
          through the Website and/or the {context.domainTitle} Application are
          solely between you and such third party and your participation is
          subject to the terms and conditions associated with that advertisement
          or promotion. You agree that {context.domainTitle} is not responsible
          or liable for any loss or damage of any sort (direct or indirect)
          incurred as the result of any such dealings or as the result of the
          presence of such third party materials on the Website or made
          available through the {context.domainTitle} Application. Reference to
          any products, services, processes, or other information, by trade
          name, trademark, manufacturer, supplier, or otherwise does not
          constitute or imply endorsement, sponsorship or recommendation thereof
          by {context.domainTitle}.
        </p>

        <h3>Service Interruptions</h3>

        <p>
          <strong>Interruptions. </strong>You acknowledge that: (i) your access
          to and use of the Website, the {context.domainTitle} Application
          and/or the provision Services may be suspended for the duration of any
          unanticipated or unscheduled downtime or unavailability of any portion
          or all of the Website, the {context.domainTitle} Application and/or
          the Services for any reason, including as a result of power outages,
          system failures or other interruptions; and (ii) we shall also be
          entitled, without any liability to you, to suspend access to any
          portion or all of the Website, the {context.domainTitle} Application
          and/or the Services at any time (a) for scheduled downtime to permit
          us to conduct maintenance or make modifications to any Service; (b) in
          the event of a denial of service attack or other attack on the Website
          and/or the {context.domainTitle} Application or other event that we
          determine, in our sole discretion, that a risk to the applicable
          Service, to you or to any of our other users may be created if the
          Service were not suspended; or (c) in the event that we determine that
          any Service is prohibited by law or we otherwise determine that it is
          necessary or prudent to do so for legal or regulatory reasons
          (collectively, "Service Interruptions").
        </p>

        <p>
          <strong>No Liability. </strong>
          {context.domainTitle} shall have no liability whatsoever for any
          damage, liabilities, losses (including any loss of data or profits) or
          any other consequences that you may incur as a result of any Service
          Interruption.
        </p>

        <p>
          <strong>Notice of Interruption. </strong>To the extent we are able, we
          will endeavor to post updates on the Website regarding any Service
          Interruption and resumption of service following any such suspension,
          but shall have no liability for the manner in which we may do so or if
          we fail to do so.
        </p>

        <p>
          <strong>Surviving Obligations. </strong>All provisions of the Terms of
          Service which by their nature should survive expiration or termination
          shall survive the expiration or termination of the Terms of Service,
          including, without limitation, ownership provisions, warranty
          disclaimers, and limitations of liability. Termination of your access
          to and use of the Website and the {context.domainTitle} Application
          shall not relieve you of any obligations arising or accruing prior to
          such expiration or termination or limit any liability which you
          otherwise may have to {context.domainTitle}, including without
          limitation any indemnification obligations contained herein.
        </p>

        <p>
          <strong>Uninstall. </strong>To uninstall the {context.domainTitle}{" "}
          Application, visit the <a href="/uninstall">uninstall instructions</a>
          .
        </p>

        <h3>Intellectual Property</h3>
        <p>
          <strong>Grant of License. </strong>
          {context.domainTitle} grants you a non-exclusive, non-transferable,
          worldwide, revocable license to access and use of the Website, the{" "}
          {context.domainTitle} Application and the Services in accordance with
          the terms set forth in the Terms of Service. The Website, the{" "}
          {context.domainTitle} Application and the Services are made available
          to you for your non-commercial use only. You need to obtain our
          permission if you want (i) to sell, distribute, license or in any
          other way exploit, in whole or in part, the Services, the use of the
          Website or the {context.domainTitle} Application (including by way of
          a bundle with other products and/or services) or any information or
          software associated with them or (ii) to modify or create derivative
          works from the Website and/or the {context.domainTitle} Application,
          in whole or in part.
        </p>

        <p>
          <strong>Reservation of Rights. </strong>
          {context.domainTitle} reserves all right, title and interest not
          expressly granted under this license to the fullest extent possible
          under applicable laws. You may not sublicense, assign, or transfer the
          license granted to you under the Terms of Service, and any attempt to
          sublicense, assign, or transfer any part of your rights under the
          Terms of Service is void.
        </p>

        <p>
          <strong>Trademarks and Copyright. </strong>The following are
          trademarks (registered or not) of {context.domainTitle}: "
          {context.domainTitle}", as well as certain other {context.domainTitle}{" "}
          trademarks, service marks, graphics, and logos (collectively, the
          "Trademarks") used in connection with the Website or the{" "}
          {context.domainTitle} Application and the provision of Services. The
          Website and/or the {context.domainTitle} Application may also contain
          third-party trademarks, service marks, graphics, and logos, including
          with respect to products and/or services that may be purchased on our
          partners' websites (collectively, the "Other Trademarks").
        </p>

        <p>
          <strong>Copyright. </strong>All content displayed on the Website and
          through the {context.domainTitle} Application as well as all the
          software (including any source code) used in connection with the
          Services is the exclusive property of {context.domainTitle} or third
          parties and is protected by copyright laws.
        </p>

        <p>
          <strong>No License. </strong>Nothing appearing on the Website will be
          construed as granting you any license, right, title or interest
          relating to the Trademarks, the Other Trademarks or other intellectual
          property used in connection with the Website, the{" "}
          {context.domainTitle} Application and/or the Services (collectively,
          the "Intellectual Property") and the Intellectual Property remains the
          exclusive property of {context.domainTitle} or owners. Except as
          stated herein, you agree not to display, copy, reproduce, use or
          distribute any Intellectual Property without our prior written
          consent. Also, you cannot mirror any material contained on the Website
          or made available through the {context.domainTitle} Application.
        </p>

        <p>
          <strong>Feedback. </strong>If you choose to communicate suggestions
          for improvements to the Website, the {context.domainTitle} Application
          or the Services (collectively, "Feedback"), we shall own all right,
          title, and interest in and to the Feedback and we shall be entitled to
          use the Feedback without restriction. You hereby irrevocably assign
          all right, title and interest in and to the Feedback to us and waive
          all you moral rights in the Feedback, and agree to provide us such
          assistance as we may require to document, perfect, and maintain our
          rights to the Feedback. You acknowledge and agree that: (i) your
          Feedback does not contain confidential or proprietary information;
          (ii) {context.domainTitle} is not under any obligation of
          confidentiality, express or implied, with respect to the Feedback;
          (iii) {context.domainTitle} shall be entitled to use or disclose (or
          choose not to use or disclose) such Feedback for any purpose, in any
          way, in any media worldwide; (iv) {context.domainTitle} may have
          something similar to the Feedback already under consideration or in
          development; and (v) you are not entitled to any compensation or
          reimbursement of any kind from {context.domainTitle} under any
          circumstances.
        </p>

        <h3>Copyrights/Trademarks</h3>

        <p>
          All content and materials available on {context.domainTitle},
          including but not limited to text, graphics, website name, code,
          images and logos are the intellectual property of{" "}
          {context.domainTitle}, and are protected by applicable copyright and
          trademark law. Any inappropriate use, including but not limited to the
          reproduction, distribution, display or transmission of any content on
          this site is strictly prohibited, unless specifically authorized by{" "}
          {context.domainTitle}.
        </p>

        <h3>Termination of Use </h3>
        <p>
          You agree that we may, at our sole discretion, suspend or terminate
          your access to all or part of our website and Resources with or
          without notice and for any reason, including, without limitation,
          breach of this User Agreement. Any suspected illegal, fraudulent or
          abusive activity may be grounds for terminating your relationship and
          may be referred to appropriate law enforcement authorities. Upon
          suspension or termination, your right to use the Resources we provide
          will immediately cease, and we reserve the right to remove or delete
          any information that you may have on file with us, including any
          account or login information.
        </p>

        <h3>Choice of Law and Venue</h3>
        <p>
          This Agreement shall be interpreted and enforced in all respects under
          the laws of Cyprus. The United Nations Convention on Contracts for the
          International Sale of Goods shall not apply to this Agreement.
        </p>

        <h3>No Guarantee </h3>
        <p>
          UNLESS OTHERWISE EXPRESSED, {context.domainTitle} EXPRESSLY DISCLAIMS
          ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
          INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES AND CONDITIONS OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
          NON-INFRINGEMENT.
        </p>

        <h3>Privacy</h3>
        <p>
          Your privacy is very important to us, which is why we've created a
          separate Privacy Policy in order to explain in detail how we collect,
          manage, process, secure, and store your private information. Our
          privacy policy is included under the scope and incorporated into this
          User Agreement. To read our privacy policy in its entirety,{" "}
          <a href="/privacy">click here</a>.
        </p>

        <h3>Miscellaneous</h3>
        <p>
          These Terms of Service along with any another notices, policies,
          procedures, and terms and conditions on our Site contain the entire
          understanding with respect to your use of our Site and our Services.
          You and we are independent contractors and nothing in these Terms of
          Service creates a partnership, joint venture, employment, or agency
          relationship. You may not assign these Terms of Service or any rights
          or obligations herein without our prior written consent and any such
          assignment or attempt thereat will be null and void. No purported
          waiver of any provision of these Terms of Service by us will be
          effective unless in a writing signed by us. If any provision of these
          Terms of Service for any reason shall be declared void, illegal,
          invalid, or unenforceable in whole or in part, such provision will be
          severable from all other provisions herein and will not affect or
          impair the validity or enforceability of any other provision of these
          Terms of Service; provided, however, that a court having jurisdiction
          may revise such provision to the extent necessary to make such
          provision valid and enforceable. The failure by us to enforce any
          provision of these Terms of Service will not be deemed a waiver of
          such provision or any other provision.
        </p>

        <h3>Disclaimers</h3>
        <p>
          <strong>"AS IS" BASIS. </strong>THE WEBSITE, THE {context.domainTitle}{" "}
          APPLICATION AND THE SERVICES (INCLUDING ALL THEIR CONTENT) ARE
          PROVIDED TO YOU "AS IS." THE DOWNLOAD OF THE {context.domainTitle}{" "}
          APPLICATION AND/OR ANY USE OF THE WEBSITE, THE {context.domainTitle}{" "}
          APPLICATION AND THE SERVICES ARE AT YOUR OWN DISCRETION AND RISK AND
          YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGES TO YOUR HARDWARE DEVICE
          OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF THE{" "}
          {context.domainTitle} APPLICATION AND/OR USE OF THE WEBSITE, THE{" "}
          {context.domainTitle} APPLICATION AND THE SERVICES. TO THE MAXIMUM
          EXTENT PERMITTED BY APPLICABLE LAW, {context.domainTitle} DISCLAIMS
          ALL REPRESENTATIONS, WARRANTIES OR CONDITIONS, EITHER EXPRESS OR
          IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OR
          CONDITIONS OF MERCHANTABILITY, MERCHANTABLE QUALITY, DURABILITY,
          FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON INFRINGEMENT.{" "}
          {context.domainTitle} MAKES NO REPRESENTATIONS, WARRANTIES OR
          GUARANTEES THAT THE WEBSITE, THE {context.domainTitle} APPLICATION AND
          THE SERVICES WILL BE FREE FROM LOSS, DESTRUCTION, DAMAGE, CORRUPTION,
          ATTACK, VIRUSES, INTERFERENCE, HACKING, OR OTHER SECURITY INTRUSION,
          AND {context.domainTitle} DISCLAIMS ANY LIABILITY RELATING THERETO.
          SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES,
          SO THE ABOVE EXCLUSIONS AND LIMITATIONS MAY OR MAY NOT APPLY TO YOU.
        </p>

        <h3>Warranty Disclaimer</h3>
        <p>
          <strong>Your Information. </strong>YOU ACKNOWLEDGE THAT YOUR
          SUBMISSION OF ANY INFORMATION ON THE WEBSITE OR VIA THE{" "}
          {context.domainTitle} APPLICATION IS AT YOUR OWN RISK.{" "}
          {context.domainTitle} DOES NOT ASSUME ANY LIABILITY TO YOU WITH REGARD
          TO ANY LOSS OR LIABILITY RELATING TO SUCH INFORMATION IN ANY WAY.
        </p>

        <p>
          <strong>Third Party Content. </strong>SOME OF THE CONTENT DISPLAYED ON
          THE WEBSITE OR THROUGH THE {context.domainTitle} APPLICATION MAY
          INCLUDE MATERIALS THAT BELONG TO OR ARE PROVIDED BY THIRD PARTIES
          (INCLUDING, BUT NOT LIMITED TO UCG). YOU ACKNOWLEDGE THAT{" "}
          {context.domainTitle} ASSUMES NO RESPONSIBILITY FOR SUCH CONTENT,
          PRODUCTS AND/OR SERVICES.
        </p>

        <p>
          <strong>Copyright Materials </strong>ALL IMAGES, LINKS, AND VIDEOS
          THAT APPEAR ON ANY SITE OR SERVICE ("SERVICE"), OWNED AND OPERATED BY{" "}
          {context.domainTitle} (HEREAFTER "{context.domainTitle} ", "WE",
          "OUR", OR "US"), ARE ASSUMED COPYRIGHTED BY THEIR RESPECTIVE OWNERS
          AND {context.domainTitle} CLAIMS NO CREDIT FOR THEM UNLESS OTHERWISE
          NOTED. IF YOU OWN THE RIGHTS TO ANY OF THE IMAGES, LINKS, OR VIDEOS
          AND DO NOT WISH THEM TO APPEAR ON THE SERVICE, PLEASE CONTACT US WITH
          THE INFORMATION REQUESTED IN THE SECTION "COPYRIGHT MATERIALS: NO
          UNAUTHORIZED USE" OF THESE TERMS OF SERVICE AND THEY WILL BE PROMPTLY
          REMOVED.
        </p>

        <p>
          FURTHERMORE, NO CONTENT WITHIN OUR SERVICE (USER COMMENTS, RATINGS,
          PHOTOS, VIDEOS, LINKS, ETC.) REPRESENTS AN IMPLIED OR EXPRESS
          ENDORSEMENT OF ANY KIND BY ANY CELEBRITY, PERSON, USER, COMPANY, TEAM,
          LEAGUE, CHARITY, SCHOOL, STORE, OR ANY OTHER ENTITY REPRESENTED OR
          FEATURED ON THE SERVICE. ASSOCIATIONS OR CONNECTIONS ON THE SERVICE
          ARE FOR ENTERTAINMENT PURPOSES ONLY.
        </p>

        <h3>Limitation of Liability & Indemnity </h3>
        <p>
          <strong>Limitation of Liability. </strong>IN NO CASE WILL{" "}
          {context.domainTitle}, ITS OFFICERS, DIRECTORS, SHAREHOLDERS,
          EMPLOYEES, AGENTS, OR PARTNERS BE LIABLE FOR ANY DIRECT,INDIRECT,
          SPECIAL, CONSEQUENTIAL, EXEMPLARY, PUNITIVE DAMAGES OR OTHER DAMAGES,
          OR FOR ANY LOSSES, DAMAGES, LIABILITIES, COSTS AND EXPENSES ARISING
          OUT OF OR RELATING TO (I) YOUR USE, MISUSE OR INABILITY TO USE THE
          WEBSITE, THE {context.domainTitle} APPLICATION OR THE SERVICES, OR
          (II) THE INTERRUPTION, SUSPENSION OR TERMINATION OF ANY PART OF OR ALL
          THE WEBSITE, THE {context.domainTitle} APPLICATION AND/OR THE
          SERVICES, OR (III) YOU RELIANCE PLACED BY YOU ON THE COMPLETNESS,
          ACCURACY OR EXISTING OF ANY ADVERTISING, OR AS A RESULT OF ANY
          RELATIONSHIP OR TRANSACTION BETWEEN YOU AND ANY ADVERTISER OR SPONSOR
          WHOSE ADVERTISING APPEARS ON THE WEBSITE OR THROUGH THE{" "}
          {context.domainTitle} APPLICATION,REGARDLESS OF THE CAUSE OF ACTION
          (WHETHER IN CONTRACT, WARRANTY, DELICT, QUASI-DELICT, TORT,
          NEGLIGENCE, STRICT LIABILITY OR ANY OTHER THEORY OF LIABILITY) AND
          EVEN IF {context.domainTitle} HAS BEEN ADVISED OF THE POSSIBILITY OF
          SUCH DAMAGES. NOTHWITHSTANDING ANYTHING TO THE CONTRARY IN THESE TERMS
          OF SERVICE, BECAUSE THE {context.domainTitle} APPLICATION IS FREE, IN
          NO EVENT WILL {context.domainTitle}�S AGGREGATE LIABILITY FOR ANY
          CLAIMS IN CONNECTION WITH YOUR USE OF THE WEBSITE, THE{" "}
          {context.domainTitle} APPLICATION AND THE SERVICES) EXCEED US$1. SOME
          JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY
          FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE EXCLUSIONS AND
          LIMITATIONS MAY OR MAY NOT APPLY TO YOU.
        </p>

        <p>
          <strong>Indemnity. </strong>YOU WILL INDEMNIFY AND HOLD{" "}
          {context.domainTitle}, ITS OFFICERS, DIRECTORS, SHAREHOLDERS, AGENTS,
          LICENSORS AND EMPLOYEES HARMLESS WITH RESPECT TO ANY SUITS, CLAIMS OR
          DEMANDS (INCLUDING REASONABLE LAWYERS' FEES) ARISING OUT OF (I) YOUR
          BREACH OF THESE TERMS OF SERVICE; (II) ANY DAMAGES BY YOU OR YOUR USE
          OF THE WEBSITE, THE {context.domainTitle} APPLICATION AND/OR THE
          SERVICESCAUSED TO A THIRD PARTY; OR (III) YOUR USE OR MISUSE OF THE
          WEBSITE, THE {context.domainTitle} APPLICATION AND/OR THE SERVICES.
        </p>

        <h3>General</h3>
        <p>
          <strong>No Partnership. </strong>No agency, partnership, joint
          venture, or employment is created between you and{" "}
          {context.domainTitle} as a result of the Terms of Service and you do
          not have any authority of any kind to bind {context.domainTitle} in
          any respect whatsoever.
        </p>
      </Container>
    </LayoutFront>
  );
};

export default Terms;
