import React, { useContext } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { AppContext } from "../AppContext";

const LayoutFront = (params) => {
  var context = useContext(AppContext);

  return (    
        <>
          <Header domainName={context.domainTitle} />
          {params.children}
          <Footer domain={context.domain} />
        </>
    
  );
};

export default LayoutFront;
