import React from "react";
import {
  AppBar,
  Button,
  Container,
  Stack,
  Box,
} from "@mui/material";

import { Link as RouterLink } from "react-router-dom";

    
const Header = ({ domainName }) => {

  return (
    <Container>
      <AppBar color="transparent" position="relative" sx={{ boxShadow:"none" }}>
        <Box marginBottom={2} marginTop={2} >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
          >
            <Button variant="text" color="inherit" LinkComponent={RouterLink} to="/">
              {domainName}
            </Button>

            <Button
              className="menuButton"
              LinkComponent={RouterLink}
              to="/"
              color="inherit"
              style={{ marginLeft: 10 }}
            >
              Home
            </Button>
          </Stack>
        </Box>
      </AppBar>
    </Container>
  );
};

export default Header;
