import "./index.css";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { HelmetProvider } from "react-helmet-async";
import Home from "./pages/Home";
import { CssBaseline } from "@mui/material";
import React from "react";

import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Uninstall from "./pages/Uninstall";
import ScrollTop from "./components/ScrollTop";
import Install from "./pages/Install";
import { AppContext } from "./AppContext";

import extractDomain from "extract-domain";
import { parseDomain, ParseResultType } from "parse-domain";
import InstallFull from "./pages/Install-fullwidth";

const { detect } = require("detect-browser");

const Capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const history = createBrowserHistory();

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      domain: "",
      domainTitle: "",
      browser: "",
      name: "",
      subdomain: "",
    };

    this.state.domain = extractDomain(window.location.hostname, { tld: true });

    if (this.state.domain === "") this.state.domain = "privacyapp.com";
    this.state.domainTitle = Capitalize(this.state.domain);

    const parseResult = parseDomain(this.state.domain);
    if (parseResult.type === ParseResultType.Listed) {
      const { subDomains, domain } = parseResult;
      this.state.subdomain = subDomains;
      this.state.name = Capitalize(domain);
    }

    var browserName = "Chrome";

    const browser = detect();

    switch (browser && browser.name) {
      case "firefox":
        browserName = "Firefox";
        break;
      case "edge-chromium":
      case "edge-ios":
      case "edge":
        browserName = "Edge";
        break;
      case "safari":
        browserName = "Safari";
        break;
      default:
    }
    this.state.browser = browserName;

    const parseQueryString = require("query-string");

    let queryString = window.location.search;
    let queryParams = parseQueryString.parse(queryString);
    this.tpl = queryParams.tm;
  }

  render() {
    return (
      <Router history={history}>
        <HelmetProvider>
          <CssBaseline />
          <ScrollTop />
          <AppContext.Provider value={this.state}>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/privacy" component={Privacy} />
              <Route exact path="/terms" component={Terms} />
              <Route exact path="/uninstall" component={Uninstall} />
              <Route exact path="/:bname/update">
                {this.tpl !== "mn" ? <Install /> : <InstallFull />}
              </Route>
            </Switch>
          </AppContext.Provider>
        </HelmetProvider>
      </Router>
    );
  }
}

export default App;
